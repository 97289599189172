import "./select-FLO-floor.css"
import Loader from "../../components/Loader/Loader";
import backArrow from "../../assets/images/backArrow.svg"
import InfoBox from "../../components/infoBox/InfoBox";
import RectangleCardFLO from "../../components/rectangleCardFlo/RectangleCardFLO";
import Select1 from "../../components/Select1";
import DownBarFLO from "../../components/downBar/DownBarFLO";
import AuthService from "../../Auth/auth";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Mod1 from "../../components/mod1/Mod1";
import alertIcon from "../../assets/images/alert.svg";

function SelectFloor({ image, productId }) {
    if (!image && !productId) {
        productId = window.localStorage.getItem('productId');
        image = window.localStorage.getItem('productSvg');
    }
    const [loading, setloading] = useState(false);
    const info = "Select the floor and the door to configure or test the FLO.";
    const [productImage, setProductImage] = useState()

    const [search, setSearch] = useSearchParams();
    const [selectedFloor, setSelectedFloor] = useState(null || search.get("floor"));
    const [label, setLabel] = useState(null || search.get("name"));

    const [floor, setFloor] = useState(null);
    const [spaces, setSpaces] = useState(null);
    const [modalDetails, setmodalDetails] = useState({
        primaryText: "Please ensure the room is vacant.",
        secondaryText: "Click below to calibrate FLO. Please ensure room is vacant during calibration.",
        icon: alertIcon,
        inputBox: false,
        confirmButtonText: "Confirm Vacant"
    });
    const [authenticate, setAuthenticate] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [dataForDownBar, setDataForDownBar] = useState({});
    const [downBar, setDownBar] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();


    const goBack = () => {
        navigate({ pathname: "/selectProduct" })
    }

    useEffect(() => {
        setProductImage(image);
    }, [image])



    //1st useEffect
    // Get the list of floor id according to the user access 
    useEffect(() => {

        let key = window.localStorage.getItem('spaceio-key');
        //console.log("keyyy", { key, authenticate })
        setAuthenticate(key ? true : false)

        function removeDuplicates(arr, key) {
            return arr.reduce((uniqueArray, obj) => {
                const index = uniqueArray.findIndex(item => item[key] === obj[key]);
                if (index === -1) {
                    uniqueArray.push(obj);
                }
                return uniqueArray;
            }, []);
        }

        async function getFloorIdAndName() {
            //let key = window.localStorage.getItem('spaceio-key');
            try {
                let productIdForApi = null;
                productIdForApi = typeof productId === "string" ? productId.includes(",") ? productId.split(",") : [productId] : productId;

                let apiResponses = [];
                let apiData = []

                //console.log("productIdForApi", typeof productIdForApi);

                for (const id of productIdForApi) {
                    let apiData = await AuthService.getFloorIdAndNameUsingProductId(key, id);
                    // console.log("api data", apiData);
                    if (apiData !== null) {
                        apiResponses = [...apiData, ...apiResponses];
                    }
                }

                apiData = removeDuplicates(apiResponses, 'id');

                //console.log(" ###################", apiData);

                let opts = apiData.map(floorData => ({ value: floorData.id, label: floorData.name }));
                setFloor(opts);
                // console.log("FLoor", floor);
                setloading(false)
            }
            catch (e) {
                setloading(false);
                setAuthenticate(key ? true : false)
            }
        }
        setloading(true);
        getFloorIdAndName();
    }, [])

    // 2nd
    // we are getting list of space id from getDeviceFromFloorAndProductID
    useEffect(() => {
        async function fetchRoom() {
            if (floor) {
                let a = floor.map((val, ind) => (val.value));
                let key = window.localStorage.getItem('spaceio-key');
                let myFloorName = null
                await floor.map((i, index) => {
                    if (i.value == selectedFloor) {
                        myFloorName = i.label
                    }
                })
                setSearch({ floor: selectedFloor, name: myFloorName })
                if (key !== null && a.includes(parseInt(selectedFloor))) {
                    setloading(true)
                    let productIdForApi = null;
                    productIdForApi = typeof productId === "string" ? productId.includes(",") ? productId.split(",") : [productId] : productId;
                    let apiResponses = [];

                    for (const id of productIdForApi) {
                        // console.log("productIdForApi@@@@@",id)
                        const spaces = await AuthService.getDeviceFromFloorAndProductID(key, selectedFloor, id);    
                        // setSearch({ floor: selectedFloor, name: myFloorName })

                        if (spaces !== null) {
                            apiResponses = [...spaces, ...apiResponses];
                        }
                    }
                    //console.log("spaces", apiResponses);

                    getCard(apiResponses)
                    // setSearch({ floor: selectedFloor })
                }
            }
        }
        // setSearch({ floor: selectedFloor, name: label })
        fetchRoom();
    }, [floor, selectedFloor])

    // 1st Function 
    // passing list of spaces
    const getCard = async (ListOfSpace) => {
        let Authkey = window.localStorage.getItem('spaceio-key');

        let FLOSpace = {};

        const rawDevice = await AuthService.getRawDevices(Authkey, selectedFloor)

        for (const [index, data] of ListOfSpace.entries()) {
            let key = data.assignedSpace || {}

            let spotDataFLO = await AuthService.getConfigZone(key.assetId)

            let myDevice = key.device.id
            let rawDeviceHardware = null;
            for (let i = 0; i < rawDevice.length; i++) {
                if (rawDevice[i].id === myDevice) {
                    rawDeviceHardware = rawDevice[i].hardware
                }
            }

            if (rawDeviceHardware) {
                key.device.hardware.mac = rawDeviceHardware.deviceId;
                key.device.hardware.agentId = rawDeviceHardware.agentId;
            }

            // let deviceStatus = await AuthService.getDeviceStatus(key.device.hardware.agentId)

            // FLOSpace[key.id] = {
            //     // status: "will be set later",
            //     deviceApi: data,
            //     assetId: key.assetId,
            //     spotDataFLO: spotDataFLO,
            //     deviceStatus: deviceStatus? deviceStatus : 'null'
            // }


            try {
                let deviceStatus = await AuthService.getDeviceStatus(key.device.hardware.agentId);
                FLOSpace[key.id] = {
                    deviceApi: data,
                    assetId: key.assetId,
                    spotDataFLO: spotDataFLO,
                    deviceStatus: deviceStatus ? deviceStatus : 'null'
                };
            } catch (error) {
                FLOSpace[key.id] = { 
                    deviceApi: data,
                    assetId: key.assetId,
                    spotDataFLO: spotDataFLO,
                    deviceStatus: 'null'
                }
                
            }
        }
        setSpaces(FLOSpace);
        setloading(false)
    }

    const addDashes = (deviceId, dashPosition) => {
        deviceId = deviceId.toString()
        return deviceId.slice(0, dashPosition[0]) + "-" + deviceId.slice(dashPosition[0], dashPosition[0] + dashPosition[1]) + "-" + deviceId.slice(dashPosition[0] + dashPosition[1], dashPosition[0] + dashPosition[1] + dashPosition[2])
    }

    const onCardClick = (floData, setupClicked) => {
        if (setupClicked) {
            floData["deviceId"] = addDashes(floData.deviceApi?.assignedSpace?.device?.blessId, [4, 3, 3]);
            setDataForDownBar(floData)
            setDownBar(!downBar)
        }
    }

    const onResetClick = async (selectedSpotFLOData) => {
        setloading(true)
        const resetData = {
            "floMetaDataConfigStatus": 0,
        }
        let dataToWrite = []
        dataToWrite = [{
            "assetId": selectedSpotFLOData.id,
            "data": {
                ...selectedSpotFLOData,
                ...resetData
            }
        }]
        await AuthService.updateFLOConfig(dataToWrite)
        window.location.reload()
        setloading(false)
    }

    const handleRectangleCardClickForSetUp = (selectedFLOSpaceData) => {
        setDataForDownBar(selectedFLOSpaceData)
        navigate("/setupFLoPage",
            { state: { ...location.state, "selectedFLOSpaceData": selectedFLOSpaceData } })
    }

    const handleRectangleCardButtonClickForZone = async (selectedFLOSpaceData) => {
        navigate(`/live-grid-flo-zones?floor=${selectedFloor}`,
            { state: { ...location.state, "selectedFLOSpaceData": selectedFLOSpaceData } })
    }

    const handleRectangleCardButtonClickForTest = async (selectedFLOSpaceData) => {
        navigate("/live-grid-flo-test",
            { state: { ...location.state, "selectedFLOSpaceData": selectedFLOSpaceData } })
    }

    return (
        <div className="container-fluid g-0">
            <div className="row justify-content-center g-0 mainDiv">
                <div className="col-lg-8" style={{ background: "#f5f5f5", display: "flex", flexDirection: "column" }}>
                    <div className="row g-0">
                        {loading && <Loader />}
                        <div className="col-11 mx-auto my-4 icon-logout-product">
                            <button style={{ border: "none", backgroundColor: "#f5f5f5" }} onClick={goBack} >
                                <img src={backArrow} alt="Back"></img>
                            </button>
                            <img src={productImage} alt="headIcon" height="33px" ></img>
                        </div>

                        <div className="col-11 my-4 mx-auto">
                            <InfoBox text={info} />
                            <label className="label-class mt-4">Select a floor</label>
                            {/* {console.log("labelll", floor)} */}

                            <Select1
                                defaultValue={selectedFloor === null ? "" : { label: label, value: selectedFloor }}
                                // defaultValue={label}
                                placeholder={'Select'}
                                options={floor !== null ? [...floor].sort((a, b) => {
                                    const labelA = a.label.toLowerCase();
                                    const labelB = b.label.toLowerCase();

                                    const isANumber = /^\d+$/.test(labelA);
                                    const isBNumber = /^\d+$/.test(labelB);

                                    if (isANumber && isBNumber) {
                                        // If both labels are numbers
                                        return Number(labelA) - Number(labelB);
                                    } else if (!isANumber && !isBNumber) {
                                        // If both labels are not numbers
                                        const ordinalA = labelA.match(/(\d+)(st|nd|rd|th)/);
                                        const ordinalB = labelB.match(/(\d+)(st|nd|rd|th)/);

                                        if (ordinalA && ordinalB) {
                                            // If both labels represent ordinal numbers
                                            const numberA = Number(ordinalA[1]);
                                            const numberB = Number(ordinalB[1]);

                                            if (numberA === numberB) {
                                                // If the numbers are equal, compare the ordinal suffixes
                                                return ordinalA[2].localeCompare(ordinalB[2]);
                                            } else {
                                                // If the numbers are different, compare numerically
                                                return numberA - numberB;
                                            }
                                        } else if (ordinalA) {
                                            // If only labelA represents an ordinal number, it comes before labelB
                                            return -1;
                                        } else if (ordinalB) {
                                            // If only labelB represents an ordinal number, it comes after labelA
                                            return 1;
                                        } else {
                                            // If neither label represents an ordinal number, compare alphabetically
                                            return labelA.localeCompare(labelB);
                                        }
                                    } else if (isANumber) {
                                        // If only labelA is a number, it comes before labelB
                                        return -1;
                                    } else {
                                        // If only labelB is a number, it comes after labelA
                                        return 1;
                                    }
                                }) : []}
                                onChange={setSelectedFloor}
                            />
                        </div>

                        {showModal ?
                            <div className="col-10 col-sm-6 col-lg-6 mx-auto ">
                                <Mod1 text={{ primaryText: modalDetails.primaryText, secondaryText: modalDetails.secondaryText, icon: modalDetails.icon }}
                                    closeModal={() => setShowModal(false)}
                                    confirmButton={{
                                        text: modalDetails.confirmButtonText, onClick: () => {
                                            onResetClick(modalDetails.confirmOnClick);
                                            setShowModal(false)
                                        }
                                    }}
                                />
                            </div>
                            : null
                        }

                        {spaces &&
                            <div className="col-11 mx-auto">
                                <label className="label-class">Select a device to proceed</label>
                                <div className="row g-0 select-floor-squareCardDiv">
                                    {Object.keys(spaces).map((key, index) => {
                                        const item = spaces[key];
                                        return (
                                            <>
                                                <RectangleCardFLO
                                                    key={index}
                                                    deviceName={item.deviceApi.displayName}
                                                    deviceId={addDashes(item.deviceApi?.assignedSpace?.device?.blessId, [4, 3, 3])}
                                                    productId={item.deviceApi?.assignedSpace?.device?.hardware?.productId}
                                                    markerId={item.deviceApi?.assignedSpace?.serial}
                                                    floMetaDataStatus={item.spotDataFLO?.floMetaDataConfigStatus}
                                                    deviceStatus={item.deviceStatus.device_status}
                                                    downbarClick={() => { onCardClick(item, true); }}
                                                    onResetClickCard={() => {
                                                        setmodalDetails({
                                                            ...modalDetails, primaryText: "Are you sure?",
                                                            secondaryText: "Reset will change the status of this FLO device to set-up",
                                                            icon: alertIcon,
                                                            confirmButtonText: "Confirm",
                                                            confirmOnClick: item.spotDataFLO
                                                        });
                                                        setShowModal(true)
                                                    }}

                                                    handleCalibrateButtonClickedForSetUp={() => {
                                                        handleRectangleCardClickForSetUp(item);
                                                    }}

                                                    handleRectangleCardButtonClickForZone={(e) => {
                                                        handleRectangleCardButtonClickForZone(item);
                                                    }}
                                                    handleRectangleCardButtonClickForTest={(e) => {
                                                        handleRectangleCardButtonClickForTest(item);
                                                    }}
                                                />
                                            </>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        }



                        <div className="col-12 mx-auto">
                            <DownBarFLO
                                deviceData={dataForDownBar}
                                className={downBar ? "downbar-card" : "downbar-card hide"}
                                closeDownBar={() => { setDownBar(!downBar) }}
                                onClick={() => handleRectangleCardClickForSetUp(dataForDownBar)}
                            />
                        </div>

                    </div>

                    {!loading && !authenticate ?
                        <div className="col-10 col-sm-6 col-lg-6 mx-auto ">
                            <Mod1 text={{ primaryText: " It seems you do not have permission to access this tool", secondaryText: "Click below to retry", icon: modalDetails.icon }}
                                closeModal=/*{() => setShowModal(false)}*/ {null}
                                confirmButton={{ text: "OK", onClick: () => { navigate("/"); setShowModal(false) } }}
                            />
                        </div>
                        : null
                    }
                    <Footer style={{ marginTop: "auto" }} />
                </div>
            </div>
        </div>
    );

}


export default SelectFloor;